import axios from '@axios'
import chartColors from '@/common/ChartColors'

export const analytics = {
  data() {
    return {
      date: {
        from: '',
        to: '',
      },
      exporting: false,
      isLoading: false,
      analyticsData: null,
      graphData: null,
      isFetchingGraph: false,
      filterBy: 'daily',
    }
  },
  created() {
    this.fetchData()
    if (this.hasGraph) {
      this.fetchGraphData()
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const response = await axios.get(`/analytics/numbers?from=${this.date.from}&to=${this.date.to}&type=${this.type}`)
      this.isLoading = false
      this.analyticsData = response.data
    },
    async fetchGraphData() {
      this.isFetchingGraph = true
      const response = await axios.get(`/analytics/graph?from=${this.date.from}&to=${this.date.to}&type=${this.type}&filter_by=${this.filterBy}`)
      this.isFetchingGraph = false
      this.graphData = this.getDataSet(response.data)
    },
    async handleExport(exportType) {
      this.exporting = true
      const response = await axios.get(`/analytics/export?from=${this.date.from}&to=${this.date.to}&type=${this.type}&file_type=${exportType}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `analytics-${this.type}-${new Date().getTime()}.${exportType}`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.exporting = false
    },
    filterGraphBy(filter) {
      this.filterBy = filter
      this.fetchGraphData()
    },
    handleExtract(date) {
      this.date = date
      this.fetchData()
      if (this.hasGraph) {
        this.fetchGraphData()
      }
    },
    getDataSet(data) {
      return {
        labels: data.labels,
        datasets: data.items.map(item => ({
          data: item.data,
          label: item.label,
          borderColor: item.color,
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: item.color,
          fill: false,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: chartColors.lineChartPrimary,
          pointHoverBackgroundColor: item.color,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        })),
      }
    },
  },
}

export const _ = null
