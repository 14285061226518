<template>
  <div v-if="analyticsData">
    <b-row class="d-flex align-items-center">
      <b-col cols="12">
        <AnalyticsDateSelector
          title="USERS DASHBOARD"
          :is-loading="isLoading"
          @handleExtract="handleExtract"
          @handleExport="handleExport"
        />
      </b-col>

      <b-col cols="12">
        <b-card>
          <b-row class="d-flex align-items-center">
            <b-col class="text-center">
              <b-img
                :src="require('@/assets/images/logo/golog_b2bpro.png')"
                width="250px"
              />
            </b-col>
            <b-col
              v-for="(item, index) in b2bProUsers"
              :key="index"
              cols="12"
              lg="2"
            >
              <StatisticsCard
                :statistics="item"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row class="d-flex align-items-center">
            <b-col class="text-center">
              <b-img
                :src="require('@/assets/images/logo/golog_smarttruck.png')"
                width="300px"
              />
            </b-col>
            <b-col
              v-for="(item, index) in smarttruckUsers"
              :key="index"
              cols="12"
              lg="2"
            >
              <StatisticsCard
                :statistics="item"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card>
          <AnalyticsLineGraph
            v-if="!isFetchingGraph"
            :title="'Users'"
            :chart-data="graphData"
            :selected-filter-by="filterBy"
            @filterGraphBy="filterGraphBy"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'dashboard' })"
        >
          Go back
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  StatisticsCard, AnalyticsLineGraph, AnalyticsDateSelector,
} from '@/components'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'UsersAnalytics',
  components: {
    StatisticsCard,
    AnalyticsLineGraph,
    AnalyticsDateSelector,
  },
  mixins: [analytics],
  data() {
    return {
      b2bProUsers: [],
      smarttruckUsers: [],
      type: 'users',
      hasGraph: true,
    }
  },
  watch: {
    analyticsData(newValue) {
      this.b2bProUsers = newValue.b2bpro_users
      this.smarttruckUsers = newValue.smarttruck_users
    },
  },
}
</script>
